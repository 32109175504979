import React from 'react';
import BrandUser from 'modelTypes/BrandUser';

import { Button } from '@mui/material';
import { Menu, MenuItem, MenuList } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.menuBackground.contrastText,
  textTransform: 'none',
  '#copy-user-dialog &': {
    color: theme.palette.primary.main,
    fontSize: '1.2rem'
  }
}))

const BrandPicker = ({
  onBrandSelected,
  currentBrand,
  brands
}: {
  onBrandSelected: (brand: string) => void,
  currentBrand: BrandUser | undefined,
  brands: BrandUser[],
}) => {
  const [brandMenuAnchor, setBrandMenuAnchor] = React.useState<null | HTMLElement>(null);

  const isBrandMenuOpen = Boolean(brandMenuAnchor);
  const onBrandMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setBrandMenuAnchor(event.currentTarget);
  };

  const onBrandMenuClosed = () => {
    setBrandMenuAnchor(null);
  }

  const onBrandChosen = (brand: string) => {
    onBrandSelected(brand)
    setBrandMenuAnchor(null);
  };

  if (!currentBrand) return <></>

  return <>
    <MenuButton
      id="demo-positioned-button"
      color='info'
      aria-controls={isBrandMenuOpen ? 'brand menu' : undefined}
      aria-haspopup="true"
      aria-expanded={isBrandMenuOpen ? 'true' : undefined}
      onClick={onBrandMenuOpen}
    >
      {currentBrand.brandDisplayName || currentBrand.brand}
      <ArrowDropDownRoundedIcon />
    </MenuButton>
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="brand-menu-button"
      anchorEl={brandMenuAnchor}
      open={isBrandMenuOpen}
      onClose={onBrandMenuClosed}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuList dense>
        {brands.map((brand, index) => (
          <MenuItem key={index} value={brand.brand} onClick={() => onBrandChosen(brand.brand)}>
            {brand.brandDisplayName || brand.brand} {brand.isPlatform ? '[Platform]' : ''}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  </>
}

export default BrandPicker;