import CircularProgress from '@mui/material/CircularProgress';
import BrandUser from 'modelTypes/BrandUser';
import React from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, MenuItem, MenuList, Toolbar, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import BrandPicker from 'components/BrandPicker/BrandPicker';

const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.menuBackground.contrastText,
  textTransform: 'none'
}))

const UserMenu = ({
  username,
  currentBrand,
  logoutHandler
}: {
  username: string,
  currentBrand: BrandUser,
  logoutHandler: () => void,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    setAnchorEl(null);
  };

  return <div>
    <MenuButton
      id="composition-button"
      aria-controls={open ? 'composition-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      aria-describedby={id}
      onClick={handleToggle}
      sx={{ pr: 0 }}
    >
      {username}
      {open ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
    </MenuButton>
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom'
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                dense
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                sx={{ textAlign: 'right' }}
              >
                {currentBrand.mayEditFrames &&
                  <MenuItem onClick={(event) => {
                    navigate('/' + currentBrand.brand + '/costs')
                    handleClose(event)
                  }
                  }>
                    {t("navigation.cost")}
                  </MenuItem>
                }
                <MenuItem onClick={(event) => {
                  navigate('/' + currentBrand.brand + '/devices')
                  handleClose(event)
                }
                }>
                  {t("navigation.devices")}
                </MenuItem>
                <MenuItem onClick={(event) => { 
                  logoutHandler() 
                  handleClose(event) }
                }>
                  {t("navigation.logout")}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </div>
}

type Props = {
  username: string | undefined,
  brandSelected: (brand: string) => void,
  logoutHandler: () => void,
  currentBrand: BrandUser | undefined,
  brands: BrandUser[],
  handleDrawerToggle: () => void,
}

const NavBar = ({
  username, brandSelected, logoutHandler, currentBrand, brands, handleDrawerToggle
}: Props) => {

  var brandMenu: React.ReactNode = <></>
  if (!currentBrand) {
    brandMenu = <Box>
      <CircularProgress />
    </Box>
  } else {
    brandMenu = <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <BrandPicker brands={brands} currentBrand={currentBrand} onBrandSelected={brandSelected} />
      {currentBrand.isPlatform && 
        <Typography variant='body1'>
          [Platform]
        </Typography>
      }
    </Box>
  }

  return <AppBar position="sticky" color={"menuBackground" as any}>
    <Toolbar variant="dense" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        {brandMenu}
      </div>
      {username && currentBrand && <UserMenu username={username} currentBrand={currentBrand} logoutHandler={logoutHandler} />}
    </Toolbar>
  </AppBar>
}

const mapStateToProps = (state: any) => ({
  username: state.user.username,
  brands: state.user.brands,
  currentBrand: state.user.currentBrand
})

export default connect(mapStateToProps, {})(NavBar);