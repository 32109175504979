import { CircularProgress, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { connect } from 'react-redux';

import { fetchDiskusage, fetchStatistics } from '../../API';

import BrandMembership from './BrandMembership';
import DayStatistics from './DayStatistics';
import MonthStatistics from './MonthStatistics';

import BrandUser from 'modelTypes/BrandUser';
import DailyStats from 'modelTypes/DailyStats';

const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
}))

const SizeFooter = styled('div')({
  textAlign: 'right'
})

type Props = {
  currentBrand: BrandUser,
  brands: BrandUser[],
  username: string
}

function Home({ currentBrand, username, brands }: Props) {
  const { t, i18n: { language } } = useTranslation();

  const [dailyStats, setDailyStats] = useState<DailyStats[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [focussedDay, setFocussedDay] = useState<string | undefined>(undefined)
  const [dayStats, setDayStats] = useState<DailyStats | undefined>(undefined)
  const [diskMBUsed, setDiskMBUsed] = useState<number | undefined>(undefined);
  const [diskUseDate, setDiskUseDate] = useState<Date | undefined>(undefined);

  // reload when branch changes
  useEffect(() => {
    const fetch = async (brand: string) => {
      setIsLoading(true)
      const today = new Date()
      var yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      var thirtyDaysAgo = new Date()
      thirtyDaysAgo.setDate(yesterday.getDate() - 30)
      const dailyData = await fetchStatistics(brand, thirtyDaysAgo, yesterday)
      setIsLoading(false)
      if (!dailyData) return
      setDailyStats(dailyData || [])
    }

    if (!currentBrand || !currentBrand.mayEditStores) return
    fetch(currentBrand.brand)
  }, [currentBrand])

  useEffect(() => {
    const fetch = async (brand: string) => {
      const response = await fetchDiskusage(brand)
      if (response && response.date && response.date.length > 5) {
        setDiskMBUsed(response.sizeInBytes)
        setDiskUseDate(new Date(response.date))
      }
    }

    if (!currentBrand || !currentBrand.mayEditStores) return
    fetch(currentBrand.brand)
  }, [currentBrand])

  useEffect(() => {
    if (dailyStats.length > 0 && focussedDay === undefined) {
      setFocussedDay(dailyStats[dailyStats.length - 1].date.split('T')[0])
    }
    setDayStats(dailyStats.find(element => element.date.split('T')[0] === focussedDay))
  }, [dailyStats, focussedDay])

  return (<Container>
    {currentBrand ?
      <Title variant="h4">
        <Trans i18nKey="home.title" values={{ brand: currentBrand.brandDisplayName }} />
      </Title>
      :
      <CircularProgress />
    }
    {currentBrand && currentBrand.mayEditUsers ?
      <>
        {currentBrand && currentBrand.isPlatform ?
          <Typography variant="subtitle1">
            {t("home.platform.manager.description")}
          </Typography>
          :
          <Typography variant="subtitle1">
            {t("home.defaultbrand.manager.description")}
          </Typography>
        }
      </>
      :
      <Typography variant="subtitle1">
        {t("home.defaultbrand.user.description", { username: username })}
      </Typography>
    }
    {brands && currentBrand && currentBrand.mayEditStores &&
      <>
        {diskMBUsed && diskUseDate && <SizeFooter>
          <Typography variant="caption">
            {t("statistics.diskusage", {
              mb: (diskMBUsed / (1024 * 1024)).toFixed(2),
              date: diskUseDate.toLocaleDateString(language, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })
            })}
          </Typography>
        </SizeFooter>}
        {isLoading ?
          <CircularProgress />
          :
          (
            dailyStats && dailyStats.length > 0 ?
              <MonthStatistics
                hasVTOData={dailyStats.some(d => (d.webVTO || 0) > 0)}
                hasAppData={dailyStats.some(d => d.appData.total > 0)}
                dailyStats={dailyStats}
                handleChoosingOfDay={setFocussedDay}
              />
              :
              <Typography variant="subtitle1">
                {t("statistics.nodata", { brand: currentBrand.brand })}
              </Typography>
          )
        }
        {dayStats && dailyStats.some(d => d.appData.total > 0) && <DayStatistics dailyStats={dayStats} />}
      </>
    }
    {brands && currentBrand && !currentBrand.mayEditStores && <BrandMembership />}
  </Container>)
}

const mapStateToProps = (state: any) => ({
  currentBrand: state.user.currentBrand,
  brands: state.user.brands,
  username: state.user.username,
})

export default connect(mapStateToProps, null)(Home)
