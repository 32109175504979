import React from 'react';
import {useTranslation} from "react-i18next";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Pie } from '@nivo/pie'

import DailyStats from 'modelTypes/DailyStats'

type Props = {
    dailyStats: DailyStats
}

function appVersionData(stats: DailyStats): any[] {
    return Object.keys(stats.appData.appVersions).map((key) => {
        return {
            id: key,
            value: stats.appData.appVersions[key]
        }
    })
}

function osVersionData(stats: DailyStats): any[] {
    return Object.keys(stats.appData.osVersions).map((key) => {
        return {
            id: key,
            value: stats.appData.osVersions[key]
        }
    })
}

function DayStatistics({ dailyStats }: Props) {
    const { t, i18n: { language } } = useTranslation();

    const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
    const date = new Date(dailyStats.date)
    const appData = appVersionData(dailyStats)
    const osData = osVersionData(dailyStats)
    const total = dailyStats.appData.total

    const parsePercent = (value: number) => {
        let ratio = value / total
        return `${(100 * ratio).toFixed(1)}%`
    }

    return <div style={{ height: 400 }}>
        <Typography variant="body1" >
            {t("statistics.dailyDetails", {
                date: date.toLocaleDateString(language, dateOptions),
                appData: dailyStats.appData.total,
                mapHits: dailyStats.mapHits,
                orders: dailyStats.orderCount
            })
            }
        </Typography>
        <Box mt={3}>
            <Grid container spacing={3}>
                <Grid item xs={12} md>
                    <Pie
                        data={appData}
                        margin={{
                            top: 0,
                            right: 60,
                            bottom: -20,
                            left: 60
                        }}
                        valueFormat={parsePercent}
                        cornerRadius={4}
                        innerRadius={0.3}
                        colors={{ "scheme": "paired" }}
                        sortByValue={true}
                        arcLabelsSkipAngle={8}
                        arcLabelsTextColor="#ffffff"
                        arcLinkLabelsDiagonalLength={4}
                        arcLinkLabelsStraightLength={12}
                        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
                        width={400}
                        height={360}
                    />
                    <Typography variant="caption" > {t("statistics.dailyDetails.appVersions.title")} </Typography>
                </Grid>
                <Grid item xs={12} md>
                    <Pie
                        data={osData}
                        margin={{
                            top: 0,
                            right: 68,
                            bottom: -20,
                            left: 60
                        }}
                        valueFormat={parsePercent}
                        cornerRadius={4}
                        innerRadius={0.3}
                        colors={{ "scheme": "paired" }}
                        sortByValue={true}
                        arcLabelsSkipAngle={8}
                        arcLabelsTextColor="#ffffff"
                        arcLinkLabelsDiagonalLength={4}
                        arcLinkLabelsStraightLength={12}
                        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
                        width={400}
                        height={360}
                    />
                    <Typography variant="caption" > {t("statistics.dailyDetails.osVersions.title")} </Typography>
                </Grid>
            </Grid>
        </Box>
    </div>
}

export default DayStatistics
