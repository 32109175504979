import React from 'react';
import { useTranslation } from "react-i18next";
import { TFunction } from 'i18next';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ResponsiveLine, Point } from '@nivo/line';

import DailyStats from 'modelTypes/DailyStats'

var RLine = ResponsiveLine as any;

const TotalLabel = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(6),
    right: 0,
}))

function tooltipDateFormat(dateString: string | number, language: string): string {
    const date = new Date(dateString as string);
    return date.toLocaleDateString(language);
}

function transform(stats: DailyStats[], t: TFunction): any[] {
    return [
        {
            id: t("statistics.legend.Orders"),
            data: stats.map(dayStats => {
                return {
                    x: dayStats.date.split('T')[0],
                    y: dayStats.orderCount
                }
            })
        },
        {
            id: t("statistics.legend.AppCheckins"),
            data: stats.map(dayStats => {
                return {
                    x: dayStats.date.split('T')[0],
                    y: dayStats.appData.total
                }
            })
        },
        {
            id: t("statistics.legend.mapHits"),
            data: stats.map(dayStats => {
                return {
                    x: dayStats.date.split('T')[0],
                    y: dayStats.mapHits
                }
            })
        }
    ]
}

function makeVTOLineData(stats: DailyStats[], t: TFunction): any[] {
    return [
        {
            id: t("statistics.legend.WebVTO"),
            data: stats.map(dayStats => {
                return {
                    x: dayStats.date.split('T')[0],
                    y: dayStats.webVTO || 0
                }
            })
        }
    ]
}

function Graph({
    language,
    data,
    handleChoosingOfDay
}: {
    language: string,
    data: any[],
    handleChoosingOfDay?: (day: string | undefined) => void
}) {
    return <RLine
        colors={{ "scheme": "paired" }}
        enableCrosshair={true}
        data={data}
        margin={{ top: 50, right: 124, bottom: 50, left: 60 }}
        axisBottom={{
            format: '%b %d',
            tickValues: 'every 2 days',
            legendOffset: -12,
        }}
        tooltip={({ point } : { point: Point}) => (
            <strong>
                {point.serieId} {tooltipDateFormat(point.data.xFormatted, language)}: {point.data.yFormatted}
            </strong>
        )}
        useMesh={true}
        onClick={(point: Point) => {
            if (handleChoosingOfDay && point && point.data && point.data.xFormatted) {
                handleChoosingOfDay((point.data.xFormatted as string).trim())
            }
        }}
        onMouseMove={(point: Point) => {
            if (handleChoosingOfDay && point && point.data && point.data.xFormatted) {
                handleChoosingOfDay((point.data.xFormatted as string).trim())
            }
        }}
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                translateX: 130,
                itemWidth: 126,
                itemHeight: 20,
                symbolShape: 'circle'
            }
        ]}
        pointLabelYOffset={-12}
        xFormat="time: %Y-%m-%d"
        xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            useUTC: false,
            precision: 'day',
        }}
    />
}

type Props = {
    hasAppData: boolean,
    hasVTOData: boolean
    dailyStats: DailyStats[],
    handleChoosingOfDay: (day: string | undefined) => void
}

function MonthStatistics({
    hasAppData,
    hasVTOData,
    dailyStats,
    handleChoosingOfDay
}: Props) {
    const { t, i18n: { language } } = useTranslation();

    return <>
        {hasVTOData && <div style={{
            height: hasAppData ? 300 : 400,
            position: 'relative'
        }}>
            <TotalLabel variant="caption" id="text-box" align="right">
                {t("statistics.legend.WebVTO.monthtotal",
                    { DAYS: dailyStats.length, SUM: dailyStats.reduce((acc, val) => acc + ((val.webVTO && parseInt(val.webVTO)) || 0), 0) }
                )}
            </TotalLabel>
            <Graph language={language} data={makeVTOLineData(dailyStats, t)} />
        </div>}
        {hasAppData && <div style={{
            height: hasVTOData ? 400 : 500,
            position: 'relative'
        }}>
            <TotalLabel variant="caption" id="text-box" align="right">
                {t("statistics.legend.AppCheckins.monthtotal",
                    { DAYS: dailyStats.length, SUM: dailyStats.reduce((acc, val) => acc + val.appData.total, 0) }
                )}
            </TotalLabel>
            <Graph language={language} data={transform(dailyStats, t)} handleChoosingOfDay={handleChoosingOfDay} />
        </div>}
    </>
}

export default MonthStatistics
