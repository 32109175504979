
import { View, Image } from '@aws-amplify/ui-react';
import Typography from '@mui/material/Typography';
import cmsIcon from '../NavigationList/CMS_Logo_icon.svg'
import { useTranslation } from "react-i18next";

const awsLoginComponents = {
  Header() {
    return (
      <View textAlign="center" padding="2rem" >
        <Image
          width="100px"
          alt="LooC CMS logo"
          src={cmsIcon}
        />
        <Typography variant='h2'> LooC CMS</Typography>
      </View>
    );
  },
  Footer() {
    const { t } = useTranslation();

    return (
      <View textAlign="center" padding="1rem">
        
        <Typography variant='body2'>
          {t("disclaimer.text")}
        </Typography>
        <Typography variant='body1'>
          © LooC GmbH
        </Typography>
      </View>
    );
  },
}

export default awsLoginComponents;