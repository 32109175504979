import { useState } from "react";

const key = "io.looc.cms.rememberMe";
const initialValue = undefined;

function useRememberMe(): [boolean | undefined, (value: boolean) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<boolean | undefined>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const localItem = localStorage.getItem(key);
      if (localItem) {
        return JSON.parse(localItem);
      }
      // Get from session storage by key
      const sessionItem = sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return sessionItem ? JSON.parse(sessionItem) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: boolean) => {
    try {
      // Save state
      setStoredValue(value);
      // Save to local storage
      if (typeof window !== "undefined") {
        if (value) {
          localStorage.setItem(key, JSON.stringify(value));
        } else {
          sessionStorage.setItem(key, JSON.stringify(value));
        }
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export default useRememberMe;