import { Amplify } from 'aws-amplify';

const awsConfig = (rememberMe) => ({
    Auth: {

        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'eu-central-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-central-1_Qg8GXUJ2v',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '13fsdi0hjqqf0mcgd9bqvb7aej',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     // domain: '.looc.io',
        //     domain: 'localhost',
        // // OPTIONAL - Cookie path
        //     // path: '/',
        // // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        // // OPTIONAL - Cookie secure flag
        // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     // secure: true
        // },

        // OPTIONAL - customized storage object
        storage: rememberMe ? localStorage : sessionStorage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    Analytics: {
        disabled: true
    }
})

const configureAmazonCognito = (rememberMe) => {
    Amplify.configure(awsConfig(rememberMe));
}


export default configureAmazonCognito