import { createReducer } from 'typesafe-actions';

import AWSUser from 'modelTypes/AWSUser'
import BrandUser from 'modelTypes/BrandUser'
import Device from 'modelTypes/Device'

const FETCH_AUTHENTICATED_USER = 'user/FETCH_AUTHENTICATED_USER'
const FETCH_USER_BRANDS = 'user/FETCH_USER_BRANDS'
const FETCH_USER_DEVICES = 'user/FETCH_USER_DEVICES'
const SET_BRANDFROMROUTE = 'user/SET_BRANDFROMROUTE'
const SET_CURRENTBRAND = 'user/SET_CURRENTBRAND'
const SET_STAGING = 'user/SET_STAGING'
const CLEAR_USER_AFTER_BRAND_CHANGE = 'user/CLEAR_USER_AFTER_BRAND_CHANGE'

type State = {
  brands: Array<BrandUser>
  devices: Array<Device>
  currentBrand: BrandUser,
  brandFromRoute: string,
  useStagingServer?: boolean,
  username: string,
  nextPageKey?: string
}

const intitialState = {
  brands: undefined,
  devices: undefined,
  currentBrand: undefined,
  brandFromRoute: undefined,
  useStagingServer: undefined,
  nextPageKey: undefined,
  username: ""
}

const reducer = createReducer(intitialState)
  .handleAction(FETCH_AUTHENTICATED_USER, (state: State, action: { type: string, user: AWSUser }) => {
    return {
      ...state,
      username: action.user.username
    }
  })
  .handleAction(FETCH_USER_DEVICES, (state: State, action: { type: string, devices: Array<Device> }) => {
    return {
      ...state,
      devices: action.devices
    }
  })
  .handleAction(FETCH_USER_BRANDS, (state: State, action: { type: string, brands: Array<BrandUser> }) => {
    if (!action.brands) {
      return state
    } else {
      var availableBrandFromRoute = action.brands.find(value => value.brand === state.brandFromRoute)
      if (state.currentBrand) {
        var updatedCurrentBand = action.brands.find(value => value.brand === state.currentBrand.brand)
        return {
          ...state,
          brands: action.brands,
          currentBrand: updatedCurrentBand
        }  
      } else if (!state.currentBrand && availableBrandFromRoute){
        return {
          ...state,
          brands: action.brands,
          currentBrand: availableBrandFromRoute
        }  
      } else {
        return {
          ...state,
          brands: action.brands,
          currentBrand: action.brands[0]
        }  
      }
    }
  })
  .handleAction(SET_CURRENTBRAND, (state: State, action: { type: string, brand: string }) => {
    var brand = state.brands.find(value => value.brand === action.brand)
    return {
      ...state,
      currentBrand: brand
    }
  })
  .handleAction(SET_STAGING, (state: State, action: { type: string, isStaging: boolean }) => {
    return {
      ...state,
      useStagingServer: action.isStaging
    }
  })
  .handleAction(SET_BRANDFROMROUTE, (state: State, action: { type: string, brand: string }) => {
    return {
      ...state,
      brandFromRoute: action.brand
    }
  })
  .handleAction(CLEAR_USER_AFTER_BRAND_CHANGE, (state: State) => {
    return {
      username: state.username,
      brands: state.brands,
      currentBrand: state.currentBrand,
      useStagingServer: state.useStagingServer
    }
  })

const full = {
  fetchUser: (user: AWSUser) => {
    return {
      type: FETCH_AUTHENTICATED_USER,
      user
    }
  },
  fetchUserDevices: (devices: Array<Device>) => {
    return {
      type: FETCH_USER_DEVICES,
      devices
    }
  },
  fetchUserBrands: (brands: Array<BrandUser>) => {
    return {
      type: FETCH_USER_BRANDS,
      brands
    }
  },
  setCurrentBrand: (brand: string) => {
    return {
      type: SET_CURRENTBRAND,
      brand
    }
  },
  setBrandFromRoute: (brand: string) => {
    return {
      type: SET_BRANDFROMROUTE,
      brand
    }
  },
  setStaging: (isStaging: boolean) => {
    return {
      type: SET_STAGING,
      isStaging
    }
  },
  clearUser: () => {
    return {
      type: CLEAR_USER_AFTER_BRAND_CHANGE
    }
  },
  reducer: reducer
}

export default full