import { combineReducers } from 'redux'
import userExport from './userReducers'
import brandExport from './brandReducers'
const user = userExport.reducer
const brand = brandExport.reducer		

const rootReducer = combineReducers({
  user,
  brand
})

export default rootReducer