import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {withTranslation} from "react-i18next";

const RootPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
}))

const StyledTable = styled(Table)(({ theme }) => ({
    minWidth: 650,
}))

function formatDate(language, dateString) {
    const dateOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };

    return (new Date(dateString)).toLocaleDateString(language, dateOptions)
}

function DeviceTable({ i18n: { language }, devices, handleDeviceDeletion, t }) {

    return <RootPaper>
        <StyledTable>
            <TableHead>
                <TableRow>
                    <TableCell>{t("devicelist.model")}</TableCell>
                    <TableCell align="right">{t("devicelist.name")}</TableCell>
                    <TableCell align="right">{t("devicelist.last-used")}</TableCell>
                    <TableCell align="right">-</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {devices.map((device, index) => (
                    <TableRow key={device.id}>
                        <TableCell component="th" scope="row"> {device.model} </TableCell>
                        <TableCell align="right">{device.name}</TableCell>
                        <TableCell align="right">{formatDate(language, device.lastUsed)}</TableCell>
                        <TableCell align="right">
                            <Button
                                variant="contained"
                                color="secondary"
                                type="button"
                                onClick={ () => { handleDeviceDeletion(device.id) } }
                            >
                                {t("delete")}
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </StyledTable>
    </RootPaper>
}

export default withTranslation()(DeviceTable);