import React from 'react';
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress';
import './Devices.css';
import { fetchDevices, deleteDevice } from '../../API';
import DeviceTable from '../DeviceTable/DeviceTable'
import {withTranslation} from "react-i18next";


class Devices extends React.Component {

    constructor(props) {
        super(props)

        this.handleDeviceDeletion = this.handleDeviceDeletion.bind(this)
    }

    componentDidMount() {
        fetchDevices()
    }

    handleDeviceDeletion(id) {
        console.log("User intends to delete device with id ", id)
        deleteDevice(id)
    }

    render() {
        const { devices, t } = this.props

        if (!devices) {
            return <div className="progressHolder">
                <CircularProgress />
            </div>
        } else if (devices.length === 0) {
            return <div>
                <h4>{t("devicelist.unregistered")}</h4>
            </div>
        } else {
            return <div>
                <h4>{t("devicelist.title")}</h4>
                <DeviceTable devices={devices} handleDeviceDeletion={this.handleDeviceDeletion} />
            </div>
        }
    }
}

const mapStateToProps = (state) => ({
    devices: state.user.devices
})

export default connect(mapStateToProps, null)(withTranslation()(Devices))
