import React from 'react';
import ReactDOM from "react-dom/client";
import './bootstrap_mod.css';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { I18nextProvider } from 'react-i18next';
import i18n from './localization/i18n';

import { createStore } from 'redux';
import { Provider } from 'react-redux';

import reducers from './reducers';
import routes from './routes';

import { subscribeToStore } from './API'

let store = createStore(reducers)

subscribeToStore(store)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            {routes}
        </Provider>
    </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
