import { createTheme } from "@mui/material";
import { blue, red, grey, yellow } from '@mui/material/colors';
import { deDE, enUS } from '@mui/x-data-grid-pro';

declare module '@mui/material/styles' {
  interface Palette {
    menuBackground: Palette['primary'];
  }
  interface PaletteOptions {
    menuBackground: PaletteOptions['primary'];
  }
}

const userLang = (navigator.language || (navigator as any).userLanguage) as string; 

const theme = createTheme(
  {
    typography: {
      fontFamily: [
        'Lato',
        'sans-serif'
      ].join(','),
      h2: {
        fontWeight: 700 // or 'bold'
      },
      body1: {
        fontWeight: 600 // or 'bold'
      },
      caption: {
        fontSize: '0.7rem',
        fontWeight: 600
      }
    },
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: blue[600],
        // dark: will be calculated from palette.primary.main,
        contrastText: '#ffffff'
      },
      secondary: {
        main: red[600],
      },
      background: {
        default: grey[100]
      },
      menuBackground: {
        main: '#051f34',
        contrastText: blue[100]
      },
      action: {
        selected: '#bb00bb'
      },
      info: {
        main: yellow[500]
      }
      // error: will use the default color
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: blue[100],
            },
          }
        }
      },
    },
  }, 
  userLang.toLowerCase().startsWith('de') ? deDE : enUS
);

export default theme;