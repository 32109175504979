import React from 'react';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { Link } from '@mui/material';

import { connect } from 'react-redux'
import userExport from '../../reducers/userReducers';

import { getJWTToken } from 'API';

import BrandUser from 'modelTypes/BrandUser';

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.menuBackground.main,
  color: theme.palette.menuBackground.contrastText,
  zIndex: theme.zIndex.drawer + 1
}))

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.menuBackground.contrastText
}))

const setStaging = userExport.setStaging

function Footer({
  isStaging,
  currentBrand,
  setStaging
}: {
  isStaging: boolean
  currentBrand: BrandUser | undefined,
  setStaging: (v: boolean) => void
}) {
  const { t, i18n: { language } } = useTranslation();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setStaging(event.target.checked)
  }
  const isChecked = isStaging !== undefined ? isStaging : false

  var fixedLanguage = language
  if (language !== "en" && language !== "de") {
    fixedLanguage = "en"
  }

  var privacyLink = `https://static.looc.io/loocfun/legal/${fixedLanguage}/privacy.html`
  if (currentBrand && currentBrand.brand) {
    privacyLink = `http://static.looc.io/${currentBrand.brand}/legal/${fixedLanguage}/privacy.html`
  }

  const handleClick = async () => {
    const jwt = await getJWTToken();
    if (jwt) navigator.clipboard.writeText(jwt)
  }

  return (
    <StyledFooter>
      <Grid container sx={{backgroundColor: 'menuBackground'}}>
        <Grid item xs={6} sm={6}>
          <Box style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: '2em'
          }}>
            <Typography variant="caption" color={isChecked ? "secondary" : "text"} sx={{ mx: 1 }}>
              {t("footer.stagingSwitch")}
            </Typography>
            <Switch size="small" checked={isChecked} onChange={handleChange} />
            <Typography variant="caption" sx={{ mx: 1 }}>
              (b{process.env.REACT_APP_GIT_SHA})
            </Typography>
            <IconButton
              color="inherit"
              aria-label="copy-to-clipboard"
              aria-haspopup="true"
              onClick={handleClick}
              size="small"
              sx={{ display: { xs: 'none', sm: 'block' }, p: 0 }}
            >
              <FileCopyOutlinedIcon sx={{ transform: 'scale(0.75)' }} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginRight: '2em'
          }}>
            <Typography variant="caption" sx={{ mr: 2, display: { xs: 'none', md: 'block' } }}>
              ©2022 LooC GmbH
            </Typography>
            <StyledLink variant="caption" sx={{ mr: 2 }} href={privacyLink}>
              {t("footer.dataprivacy")}
            </StyledLink>
            <StyledLink variant="caption" sx={{ mr: 2 }} href="https://looc.io/imprint">
              {t("footer.imprint")}
            </StyledLink>
            <StyledLink variant="caption" href="mailto:feedback@looc.io">
              {t("footer.contact")}
            </StyledLink>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mr: 2, display: { xs: 'block', md: 'none' }, textAlign: 'center' }}>
          <Typography variant="caption" sx={{ mr: 2 }}>
            ©2022 LooC GmbH
          </Typography>
        </Grid>
      </Grid>
    </StyledFooter >
  );
}

const mapStateToProps = (state: any) => ({
  isStaging: state.user.useStagingServer,
  currentBrand: state.user.currentBrand
})

const ConnectedFooter = connect(mapStateToProps, {
  setStaging: setStaging,
})(Footer)

export default ConnectedFooter
