import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import loadable from '@loadable/component';
import App from './components/App/App'
import Home from './components/Home/Home'
import Devices from './components/Devices/Devices'
import Privacy from './components/Privacy/Privacy'

import CircularProgress from '@mui/material/CircularProgress';

const BrandUsers = loadable(() => import('./components/BrandUsers/BrandUsers'), { fallback: <CircularProgress /> });
const CreateBrandUser = loadable(() => import('./components/CreateBrandUser/CreateBrandUser'), { fallback: <CircularProgress /> });
const Stores = loadable(() => import('./components/Store/Stores'), { fallback: <CircularProgress /> });
const Orders = loadable(() => import('./components/Orders/Orders'), { fallback: <CircularProgress /> });
const OrderDetails = loadable(() => import('./components/OrderDetails/OrderDetails'), { fallback: <CircularProgress /> });
const Categories = loadable(() => import('./components/Categories/Categories'), { fallback: <CircularProgress /> });
const CategoryContent = loadable(() => import('./components/Models/CategoryContent'), { fallback: <CircularProgress /> });
const CreateCategory = loadable(() => import('./components/Categories/CreateCategory'), { fallback: <CircularProgress /> });
const FrameUpload = loadable(() => import('./components/FrameUpload/FrameUpload'), { fallback: <CircularProgress /> });
const Renderings = loadable(() => import('./components/Renderings/Renderings'), { fallback: <CircularProgress /> });
const ModelViewer = loadable(() => import('./components/GoogleModelViewer/GoogleModelViewer'), { fallback: <CircularProgress /> });
const RenderingRequest = loadable(() => import('./components/RenderingRequest/RenderingRequest'), { fallback: <CircularProgress /> });
const BatchRenderingRequest = loadable(() => import('./components/Renderings/BatchRenderingRequest'), { fallback: <CircularProgress /> });
const Materials = loadable(() => import('./components/Materials/Materials'), { fallback: <CircularProgress /> });
const MaterialDetail = loadable(() => import('./components/Materials/MaterialDetail'), { fallback: <CircularProgress /> });
const Costs = loadable(() => import('./components/Costs/Costs'), { fallback: <CircularProgress /> });
const Builds = loadable(() => import('./components/Builds/BuildOverview'), { fallback: <CircularProgress /> });
const Settings = loadable(() => import('./components/Builds/Settings'), { fallback: <CircularProgress /> });
const AppConfigOverview = loadable(() => import('./components/AppConfig/AppConfigOverview'), { fallback: <CircularProgress /> });
const AppConfigEdit = loadable(() => import('./components/AppConfig/AppConfigEdit'), { fallback: <CircularProgress /> });

export default (
  <BrowserRouter>
    <App>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:brand" element={<Home />} />
        <Route path="/:brand/users" element={<BrandUsers />} />
        <Route path="/:brand/users/create" element={<CreateBrandUser />} />
        <Route path="/:brand/users/edit/:user" element={<CreateBrandUser />} />
        <Route path="/:brand/users/orders/:user" element={<Orders />} />
        <Route path="/:brand/devices" element={<Devices />} />
        <Route path="/:brand/stores" element={<Stores />} />
        <Route path="/:brand/orders" element={<Orders />} />
        <Route path="/:brand/orders/:orderSK" element={<OrderDetails />} />
        <Route path="/:brand/appconfig" element={<AppConfigOverview />} />
        <Route path="/:brand/appconfig/edit" element={<AppConfigEdit />} />
        <Route path="/:brand/frame-categories" element={<Categories />} />
        <Route path="/:brand/frame-categories/create" element={<CreateCategory />} />
        <Route path="/:brand/frame-categories/:category/show" element={<CategoryContent />} />
        <Route path="/:brand/frame-categories/:category/edit" element={<CreateCategory />} />
        <Route path="/:brand/frame-categories/:category/create" element={<FrameUpload />} />
        <Route path="/:brand/frame-categories/:category/:model/edit" element={<FrameUpload />} />
        <Route path="/:brand/materials" element={<Materials />} />
        <Route path="/:brand/materials/create" element={<MaterialDetail />} />
        <Route path="/:brand/materials/:identifier/edit" element={<MaterialDetail />} />
        <Route path="/:brand/renderings/:category/:model/:type" element={<Renderings />} />
        <Route path="/:brand/renderings" element={<Renderings />} />
        <Route path="/:brand/renderings/:category" element={<Renderings />} />
        <Route path="/:brand/renderings/:category/:model" element={<Renderings />} />
        <Route path="/:brand/rendering/create" element={<RenderingRequest />} />
        <Route path="/:brand/rendering/batch" element={<BatchRenderingRequest />} />
        <Route path="/:brand/rendering/modelviewer" element={<ModelViewer />} />
        <Route path="/:brand/costs" element={<Costs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/:brand/builds" element={<Builds />} />
        <Route path="/:brand/builds/settings/:key" element={<Settings />} />
      </Routes>
    </App>
  </BrowserRouter>
)
