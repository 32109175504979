import React from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import userExport from '../../reducers/userReducers';

import { Box, Button, Paper, Typography, Grid } from '@mui/material';

import BrandUser from 'modelTypes/BrandUser'

type Props = {
  currentBrand: BrandUser,
  setCurrentBrand: (brand: string) => {
    type: string;
    brand: string;
  },
  brands: BrandUser[],
}

function BrandMembership({ currentBrand, setCurrentBrand, brands }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const brandSelected = (brand: string) => {
    console.log("User selected brand ", brand)
    setCurrentBrand(brand)

    navigate(`/${brand}`)
  }

  console.log("BrandMembership: brands: ", brands)

  return (<>
    <Typography m={2} variant="h6" component="h2" gutterBottom>
      {t('home.brandMembership.title')}
    </Typography>
    <Grid container spacing={3}>
      {brands.map((brand) => {
        return <Grid item xs={6} sm={4} md={3} key={brand.brand}>
          <Paper elevation={1}>
            <Box p={1}>
              <Typography variant="body1" component="p">
                {brand.brandDisplayName || brand.brand}:
              </Typography>
              <Typography m={1} variant="body2" component="p">
                {brand.mayEditUsers && brand.mayEditManagers && 'Admin'}
                {brand.mayEditUsers && !brand.mayEditManagers && t('usercreate.form.role-manager')}
                {!brand.mayEditUsers && t('usercreate.form.role-store')}
              </Typography>
              <Button variant='outlined' disabled={brand.brand === currentBrand.brand} onClick={() => brandSelected(brand.brand)}>
                {t('home.brandMembership.switch')}
              </Button>
            </Box>
          </Paper>
        </Grid>
      })}
    </Grid>
  </>)
}

const mapStateToProps = (state: any) => ({
  currentBrand: state.user ? state.user.currentBrand : undefined,
  brands: state.user.brands
})
const Connected = connect(mapStateToProps, {
  setCurrentBrand: userExport.setCurrentBrand,
  setBrandFromRoute: userExport.setBrandFromRoute
})(BrandMembership)

export default Connected
