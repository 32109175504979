import { Auth } from 'aws-amplify';
import userExport from './reducers/userReducers';
import brandExport from './reducers/brandReducers';
import BrandUser from 'modelTypes/BrandUser'
import Category from 'modelTypes/Category';
import Model from 'modelTypes/Model';
import Rendering from 'modelTypes/Rendering';
import Material from 'modelTypes/Material';
import StoreModel from 'modelTypes/StoreModel';
import AWSUser from 'modelTypes/AWSUser'

const storeUser = userExport.fetchUser
const storeBrands = userExport.fetchUserBrands
const storeDevices = userExport.fetchUserDevices
const clearUserStore = userExport.clearUser
const setCategories = brandExport.setCategories
const setMaterials = brandExport.setMaterials
const setModels = brandExport.setModels
const setTags = brandExport.setTags

type Services = 'main' | 'config' | 'renderings' | 'materials' | 'builds' | 'users' | 'stores' | 'stats' | 'metafiles'

function baseUrl(service: Services = 'main') {
    const baseUrl = `https://api.looc.io/${service === 'main' ? 'prod' : service}`
    const baseUrlStaging = "https://api.looc.io/staging"

    const useStagingServer = store.getState().user ? store.getState().user.useStagingServer : false
    return useStagingServer ? baseUrlStaging : baseUrl
}

export async function setUserFromAuthenticator(user: AWSUser) {
    let currentUserName = store.getState().user.username
    if (currentUserName !== user.username) {
        store.dispatch(storeUser(user))
        fetchBrands()
        fetchDevices()    
    }
}

export async function fetchBrands() {
    let brands = await cmsGet('user', {})
    store.dispatch(storeBrands(brands))
}

export async function fetchDevices() {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        return
    }
   let devices = await cmsGet('devices', { brand: currentBrand.brand })
   store.dispatch(storeDevices(devices))        
}

export async function deleteDevice(id: string) {
    await cmsDelete('device', id, () => { fetchDevices() })
}

//#region Orders

export async function fetchOrders(nextPageKey: string | undefined) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Can't fetch orders as long as no currentBrand is selected")
        return
    }

    var params: { brand: string, store: boolean, perPage: number, nextPageKey?: string } = {
        brand: currentBrand.brand, 
        store: currentBrand.role === "store", 
        perPage: 40 
    }

    if (nextPageKey) params.nextPageKey = nextPageKey

    return cmsGet('v2/orders', params)
}

export async function fetchOrdersForUser(userId: string, nextPageKey: string | undefined) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Can't fetch orders as long as no currentBrand is selected")
        return
    }

    var params: {brand: string, store: boolean, user: string, perPage: number, nextPageKey?: string} = {
        brand: currentBrand.brand, 
        store: true, 
        user: userId,
        perPage: 20 
    }

    if (nextPageKey) params.nextPageKey = nextPageKey

    return cmsGet('v2/orders', params)
}

export async function fetchOrder(orderSK: string, brand: string) {
    return cmsGet(`order/${encodeURIComponent(orderSK)}`, { brand: brand })
}

//#endregion

//#region BrandUsers

export async function fetchBrandUsers(
    nextPageKey: string | undefined,
    brand: BrandUser,
    checkLastSeen: boolean,
    forBrand?: string
) {
    if (!(brand.mayEditManagers || brand.mayEditUsers) && !(brand.isPlatform && forBrand)) {
        console.log("User doesn't have the accessLvl to see users of brand ", currentBrand.brand)
        return undefined
    }

    var params: {brand: string, perPage: number, fetchLastUsed?: string, nextPageKey?: string, forBrand?: string} = {
        brand: brand.brand, 
        perPage: 200 
    }
    if (forBrand) params.forBrand = forBrand;
    if (checkLastSeen) params.fetchLastUsed = 'true';
    if (nextPageKey) params.nextPageKey = nextPageKey

    return cmsGet(`${brand.brand}/users`, params, 'users')
}

// TODO: fill out values later
type BrandUserValues = any

export async function createBrandUser(values: BrandUserValues) {
    const url = new URL(baseUrl('users') + '/user')
    console.log("Creating or updating user with values ", values)
    return cmsPost(url, values)
}

export async function deleteBrandUser(email: string, finished: () => void) {
    await cmsDelete('user', encodeURIComponent(email), () => { finished() }, 'users')
}

export async function fetchUser(brand: string, email: string) {
    return cmsGet(`${brand}/user/${email}`, {}, 'users')
}

export async function fetchBrandsOfUser(email: string) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Can't fetch users as long as no currentBrand is selected")
        return undefined
    }
    return cmsGet(`${currentBrand.brand}/brands-of-user/${email}`, {}, 'users')
}

export async function resendUserInvite(email: string, brand: string) {
    var url = new URL(baseUrl() + '/user/resend')
    return cmsPost(url, {id: email, brand: brand})
}

export async function refreshAppDataCache() {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Can't fetch categories as long as no currentBrand is selected")
        return
    }
    const url = new URL(baseUrl() + `/${currentBrand.brand}/refresh-app-data`)
    console.log("Triggering refresh of AppData for ", currentBrand.brand)
    return cmsPost(url, {})
}

//#endregion

//#region AppConfig

export async function fetchAppConfig(brand: string, stage: 'test' | 'prod') {
    return cmsGet(`${brand}/appconfig`, stage === 'test' ? {"testing": "true"} : {}, 'config')
}

export async function postAppConfig(brand: string, values: any) {
    const url = new URL(baseUrl('config') + '/' + brand + '/appconfig')
    console.log("Posting config with values ", values)
    return cmsPost(url, values)
}

export async function publishTestConfig(brand: string) {
    const url = new URL(baseUrl('config') + '/' + brand + '/appconfig/publish')
    return cmsPost(url, {})
}

//#endregion

//#region Categories

export async function fetchCategories() {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Can't fetch categories as long as no currentBrand is selected")
        return
    }
    const categories = await fetchCategoriesForBrand(currentBrand.brand)
    store.dispatch(setCategories(categories))
}

export async function fetchCategoriesForBrand(brandName: string) {
    const categories = await cmsGet(`${brandName}/categories`, {})
    return categories as unknown as Category[]
}

export async function deleteCategory(name: string) {
    await cmsDelete(`${currentBrand.brand}/categories`, name, () => { 
        var categories: Array<Category> = store.getState().brand.categories
        categories = categories.filter(cat => cat.name !== name)
        store.dispatch(setCategories(categories))
    })
}

export async function setCategoryStatus(category: string, status: string | undefined, promoted: Boolean | undefined) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand || !category) {
        console.log("Can't set status as long as no currentBrand is selected")
        return
    }
    const url = new URL(baseUrl() + `/${currentBrand.brand}/category/${category}/setStatus`)
    const response: any = await cmsPost(url, {status: status, promoted: promoted})
    const json = await response.json()
    var categories: Array<Category> = store.getState().brand.categories
    if (json.item && categories) {
        categories = categories.filter(cat => cat.name !== json.item.name)
        categories.push(json.item)
        store.dispatch(setCategories(categories))
    }
    return response
}

export async function createCategory(values: any) {
    var url = new URL(baseUrl() + `/${currentBrand.brand}/categories`)
    return cmsPost(url, values)
}

//#endregion

//#region Models

export async function setModelStatus(modelName: string, category: string, status: string) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand || !category || !modelName) {
        console.log("Can't set status as long as no currentBrand, category and modelName is selected")
        return
    }
    const url = new URL(baseUrl() + `/${currentBrand.brand}/category/${category}/models/${modelName}/setStatus`)
    const response: any = await cmsPost(url, {status: status})
    const json = await response.json()
    const models: { [category: string]: Array<Model> } = store.getState().brand.models
    var modelsForThisCat = models[category] 
    if (json.item && modelsForThisCat) {
        modelsForThisCat = modelsForThisCat.filter(val => val.name !== json.item.name)
        modelsForThisCat.push(json.item)
        modelsForThisCat = modelsForThisCat.sort((a, b) => a.name < b.name ? -1 : 1)
        store.dispatch(setModels(category, modelsForThisCat))
    }
    return response
}

export async function fetchModels(category: string) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand || !category) {
        console.log("Can't fetch categories as long as no currentBrand is selected")
        return
    }
    const models = await cmsGet(`${currentBrand.brand}/category/${category}/models`, {})
    store.dispatch(setModels(category, models))
}

export async function fetchModel(category: string, id: string) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand || !category) {
        console.log("Can't fetch models as long as no currentBrand is selected")
        return
    }

    return cmsGet(`${currentBrand.brand}/category/${category}/models/${id}`, {})
}

export async function deleteModel(category: string, name: string) {
    await cmsDelete(`${currentBrand.brand}/category/${category}/models`, name, () => {
        fetchModels(category) 
    })
}

export async function createModel(category: string, values: Model) {
    const url = new URL(baseUrl() + `/${currentBrand.brand}/category/${category}/models`)
    console.log(`Creating with values in category ${category}:`)
    console.log(values)
    return cmsPost(url, values)
}

export async function copyModel(category: string, modelID: string, newBrand: string, newCategory: string) {
    const url = new URL(baseUrl() + `/${currentBrand.brand}/category/${category}/models/${modelID}`)
    return cmsPost(url, { brand: newBrand, category: newCategory })
}

//#endregion

//#region Meta Model files

export async function fetchMetaFiles(category: string, modelId: string) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand || !category) {
        console.log("Can't fetch metafiles as long as no currentBrand is selected")
        return
    }
    return cmsGet(`${currentBrand.brand}/category/${category}/${modelId}/files`, {}, 'metafiles')
}

export async function requestUploadUrl(category: string, modelId: string, fileName: string) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand || !category) {
        console.log("Can't fetch uploadURL as long as no currentBrand is selected")
        return
    }
    var query = { fileName: fileName }
    return cmsGet(`${currentBrand.brand}/category/${category}/${modelId}/getupload`, query, 'metafiles')
}

export async function requestDownloadUrl(category: string, modelId: string, fileName: string) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand || !category) {
        console.log("Can't fetch downloadURL as long as no currentBrand is selected")
        return
    }
    var query = { fileName: fileName }
    return cmsGet(`${currentBrand.brand}/category/${category}/${modelId}/getdownload`, query, 'metafiles')
}

export async function deleteMetaFile(category: string, modelId: string, fileName: string) {
    let currentBrand: BrandUser = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Deleting only works with currentBrand selected")
        return
    }
    var query = { fileName: fileName }
    return cmsDelete(undefined, `${currentBrand.brand}/category/${category}/${modelId}/files`, () => { }, 'metafiles', query)
}

//#endregion

export async function fetchTags() {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Can't fetch tags as long as no currentBrand is selected")
        return
    }
    
    const tags = await cmsGet(`/tags/${currentBrand.brand}`, {})
    store.dispatch(setTags(tags))
    return
}

export async function createTag(named: string) {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Can't create tags as long as no currentBrand is selected")
        return
    }

    const url = new URL(baseUrl() + `/tags/${currentBrand.brand}`)

    const response = await cmsPost(url, { name: named})
    console.log("Creating tag response: ", response)
    fetchTags()
}

export async function fetchRenderCosts(brand: string, year: string, month: string) {
    var query: {[key: string]: string } = {
        year: year,
        month: month
    }

    return cmsGet(`${brand}/costs`, query, 'renderings')
}

export async function fetchStatistics(brand: string, from: Date, to: Date) {
    var query = {
        from: from.toISOString(),
        to: to.toISOString()
    }

    return cmsGet(brand, query, 'stats')
}

export async function fetchDiskusage(brand: string) {
    return cmsGet(`${brand}/diskusage`, {}, 'stats')
}

export async function fetchReceipts(brand: string, nextPageKey: string | undefined) {
    var query: {[key: string]: string } = { }
    if (nextPageKey) query.nextPageKey = nextPageKey

    return cmsGet(`${brand}/receipts`, query, 'renderings')
}

//#region Renderings

export async function fetchRenderings(nextPageKey: string | undefined, brand: string, category?: string, modelId?: string, type?: string, order?: "asc" | "desc") {
    var query: {[key: string]: string } =  {}
    if (category) query.category = category
    if (modelId) query.model = modelId
    if (type) query.type = type
    if (order) query.order = order

    if (nextPageKey) query.nextPageKey = nextPageKey

    return cmsGet(`${brand}/renderings`, query, 'renderings')
}

export async function requestRendering(
    brand: string,
    parameters: {[key: string]: any},
    category?: string,
    modelId?: string
) {
    const url = new URL(baseUrl() + `${brand}/renderings`)
    await cmsPost(url, { 
        category: category,
        model: modelId,
        parameters: parameters
    })
}

export async function postRendering(brand: string, values: any) {
    const url = new URL(baseUrl('renderings') + `/${brand}/renderings`)
    console.log("Posting rendering with values ", values)
    return cmsPost(url, values)
}

export async function clearCache(brand: string) {
    const url = new URL(baseUrl('renderings') + `/${brand}/clearcache`)
    console.log("clearcache rendering with of brand ", brand)
    return cmsPost(url, {})
}

export async function deleteRendering(rendering: Rendering) {
    let currentBrand: BrandUser = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Deleting only works with currentBrand selected")
        return
    }
    console.log("Deleting rendering ", rendering)
    return cmsDelete(undefined, `${currentBrand.brand}/renderings/${rendering.category}/${rendering.model}/${rendering.created}`, () => { }, 'renderings')
}

export async function deleteRenderingId(renderingId: string) {
    let currentBrand: BrandUser = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Deleting only works with currentBrand selected")
        return
    }
    console.log("Deleting rendering ", renderingId)
    return cmsDelete(undefined, `${currentBrand.brand}/renderings/${renderingId}`, () => { }, 'renderings')
}

//#endregion

//#region Build settings

export async function fetchSettings(brand: string, name: string) {
    return cmsGet(`${brand}/${name}`, {}, 'builds')
}

export async function postSettings(brand: string, name: string, values: any) {
    const url = new URL(baseUrl('builds') + `/${brand}/${name}`)
    console.log("Posting ", name, " for brand ", brand, " with values ", values)
    return cmsPost(url, values)
}

export async function duplicateBrand(brand: string, values: any) {
    const url = new URL(baseUrl('builds') + `/${brand}/duplicate`)
    console.log("Duplicating brand ", brand, " with values ", values)
    return cmsPost(url, values)
}

//#endregion

//#region Materials

export async function fetchMaterials() {
    let currentBrand = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Can't fetch categories as long as no currentBrand is selected")
        return
    }
    const materials = (await fetchMaterialsForBrand(currentBrand.brand)).items as unknown as Material[]
    store.dispatch(setMaterials(materials))
}

export async function fetchMaterialsForBrand(brand: string, type?: string, nextPageKey?: string) {
    var query: {[key: string]: string } = { }
    if (nextPageKey) query.nextPageKey = nextPageKey
    if (type) query.type = type
    query.perPage = "100"

    return cmsGet(`${brand}/materials`, query, 'materials')
}

export async function postMaterial(brand: string, values: any) {
    const url = new URL(baseUrl('materials') + `/${brand}/materials`)
    console.log("Posting material with values ", values)
    return cmsPost(url, values)
}

export async function deleteMaterial(material: Material) {
    let currentBrand: BrandUser = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Deleting only works with currentBrand selected")
        return
    }
    console.log("Deleting material ", material)
    return cmsDelete(undefined, `${currentBrand.brand}/materials/${material.type}/${material.identifier}`, () => { }, 'materials')
}

//#endregion

//#region Stores

export async function fetchStores(brand: string, user?: string, nextPageKey?: string) {
    var query: {[key: string]: string } = { }
    if (nextPageKey) query.nextPageKey = nextPageKey
    if (user) query.user = user

    return cmsGet(`${brand}`, query, 'stores', false)
}

export async function postStores(brand: string, user: string, stores: StoreModel[]) {
    const url = new URL(baseUrl('stores') + `/${brand}/${user}`)
    console.log("Posting stores with values ", stores)
    return cmsPost(url, stores)
}

export async function copyStores(fromBrand: string, user: string, toBrand: string) {
    const url = new URL(baseUrl('stores') + `/copy/${fromBrand}/${user}/${toBrand}`)
    console.log(`Copying stores from ${fromBrand} to ${toBrand}`)
    return cmsPost(url, {})
}

export async function issueStoreGeolocationBuilding(brand: string) {
    console.log("Issueing store to geolocation")
    const url = new URL(baseUrl('stores') + `/geo/${brand}/populate`)
    return cmsPost(url, {})
}

export async function deleteStore(user: string, hash: string) {
    let currentBrand: BrandUser = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Deleting only works with currentBrand selected")
        return
    }
    console.log("Deleting store ", `${user}-${hash}`)
    return cmsDelete(undefined, `${currentBrand.brand}/${user}/${hash}`, () => { }, 'stores')
}

//#endregion

export async function getJWTToken() {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken()
}

async function cmsGet(path: string, params: { [key: string]: any }, service: Services = 'main', useCached: boolean = true) {
    try {
        const session = await Auth.currentSession()
        const jwtToken = session.getIdToken().getJwtToken()

        // console.log("jwtToken: ", jwtToken)
    
        const url = new URL(baseUrl(service) + '/' + path)
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

        console.log("Fetching ", url.toString(), " from CMS API")

        var headers: {[key: string]: string} = {
            'Authorization': 'Bearer ' + jwtToken
        }
        if (!useCached) headers['Cache-Control'] = 'max-age=0'

        const response = await fetch(url.href, {
            method: 'GET',
            headers: headers
        })

        if (response.ok) {
            let json = await response.json()
            return json
        } else {
            let text = await response.text()
            console.log(`Failed to load ${path} due to ${text}`)
        }
    } catch (error) {
        console.warn(`Not calling ${path} due to: `, error)
    }
}

async function cmsDelete(path: string | undefined, id: string, callback: () => void, service: Services = 'main', query: {[key: string]: string } = {}) {
    let currentBrand: BrandUser = store.getState().user.currentBrand
    if (!currentBrand || !currentBrand.brand) {
        console.log("Deleting only works with currentBrand selected")
        return
    }
    try {
        let session = await Auth.currentSession()
        let jwtToken = session.getIdToken().getJwtToken()
        var url = new URL(baseUrl(service) + (path ? ('/' + path) : '') + '/' + id)
        var params: {[key: string]: string } = { brand: currentBrand.brand }
        Object.keys(query).forEach(key => params[key] = query[key])
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

        console.log("Deleting " + url + ", for brand ", currentBrand.brand)
        const settings = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + jwtToken,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(url.href, settings)

        if (response.ok) {
            callback()
        }
        const text = await response.text()
        console.log("Deletion response: ", text)
    } catch (error) {
        console.error(error)
    }
}

async function cmsPost(url: URL, body: any) {
    const session = await Auth.currentSession()
    const jwtToken = session.getIdToken().getJwtToken()    

    console.log("Posting to ", url, " from CMS API")

    const settings = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + jwtToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    };

    const response = await fetch(url.href, settings)
    if (response.ok) {
        return response
    } else {
        let text = await response.text()
        throw new Error(`Failed to post to ${url} due to: ${text}`)
    }
}

var currentBrand: BrandUser
function handleBrandChange() {
    let previousValue = currentBrand
    
    let user = store.getState().user
    currentBrand = user ? user.currentBrand : undefined

    const brandHasChanged = previousValue?.brand !== currentBrand?.brand
    if (brandHasChanged) {
        console.log("Brand changed, refreshing contents")
        store.dispatch(clearUserStore())
        fetchCategories()
        fetchMaterials()
        fetchTags()
        fetchDevices()
    }
}

let store: any
export function subscribeToStore(storeInput: any) {
    store = storeInput
    store.subscribe(handleBrandChange)
}
