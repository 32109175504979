import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Box, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { Auth } from 'aws-amplify';

import NavigationList from 'components/NavigationList/NavigationList';
import theme from 'theme';
import { fetchBrands, setUserFromAuthenticator } from '../../API';
import CookieContext from '../../helper/CookieContext';
import useLocalStorage from '../../helper/useLocalStorage';
import useRememberMe from '../../helper/useRememberMe';
import userExport from '../../reducers/userReducers';
import CookiePopup from '../CookiePopup/CookiePopup';
import Footer from '../Footer/Footer';
import NavBar from '../Navbar/Navbar';
import configureAmazonCognito from './AWSConfig.js';
import awsLoginComponents from './AWSLoginComponents';
import './App.css';

import BrandUser from 'modelTypes/BrandUser';

LicenseInfo.setLicenseKey(
  'e66752f8869551c24d530475120af7eeTz02MDc3MixFPTE3MDkwNTEzNDgzOTcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

type Props = {
  currentBrand: BrandUser | undefined,
  setCurrentBrand: (brand: string) => {
    type: string;
    brand: string;
  },
  setBrandFromRoute: (brand: string) => {
    type: string;
    brand: string;
  }
}

const drawerCollapsedStorageKey = 'io.looc.drawerOpenStorageKey'
const savedLocalBrandStorageKey = 'io.looc.savedLocalBrandStorageKey'

const App: React.FC<React.PropsWithChildren<Props>> = ({
  currentBrand,
  setCurrentBrand,
  setBrandFromRoute,
  children
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [allowCookies, setAllowCookies] = useRememberMe();
  const [localSavedBrand, setLocalSavedBrand] = useLocalStorage<string | undefined>(savedLocalBrandStorageKey, undefined, allowCookies || false)
  const [isDesktopDrawerCollapsed, setIsDesktopDrawerCollapsed] = useLocalStorage(drawerCollapsedStorageKey, false, allowCookies || false)

  const drawerWidth = isSmall || !isDesktopDrawerCollapsed ? 200 : 58;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (currentBrand)
      setLocalSavedBrand(currentBrand.brand)    
  }, [currentBrand, setLocalSavedBrand]);

  useEffect(() => {
    // console.log("configureAmazonCognito with localStorage: ", allowCookies)
    configureAmazonCognito(allowCookies)    
  }, [allowCookies]);

  const path = location.pathname
  const currentBrandName = currentBrand?.brand

  useEffect(() => {
    const pathComponents = path.split("/")
    pathComponents.shift()
    if (pathComponents.length > 0) {
      const brand = pathComponents[0]
      if (!currentBrandName || currentBrandName !== brand) {
        setBrandFromRoute(brand)
      }
    } else if (!currentBrandName && localSavedBrand) {
      console.log("Setting brand from local storage")
      setBrandFromRoute(localSavedBrand)
    }

    async function fetchUserData() {
      await fetchBrands()
    }

    console.log("Fetching user data")
    fetchUserData();
  }, [currentBrandName, setBrandFromRoute, path, localSavedBrand])

  const logoutHandler = () => {
    console.log("Logout clicked")
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err))
  }

  const brandSelected = (brand: string) => {
    console.log("User selected brand ", brand)
    setCurrentBrand(brand)

    let oldPath = location.pathname

    if (oldPath.includes('/users') ||
      oldPath.includes('/devices') ||
      oldPath.includes('/orders') ||
      oldPath.includes('/frames')) {

      const pathComponents = oldPath.split("/")
      pathComponents.shift() // remove the empty string
      pathComponents.shift() // and the brand
      const newPath = '/' + brand + '/' + pathComponents.join('/')
      navigate(newPath)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CookieContext.Provider value={allowCookies || false}>
        <Authenticator hideSignUp={true} components={awsLoginComponents}>
          {({ signOut, user }) => {
            if (user)
              setUserFromAuthenticator(user as any);

            return <>
              <CssBaseline />
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Box sx={{ display: 'flex', flex: 1, overflowY: 'clip' }}>
                  <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="navigation items folders"
                  >
                    <Drawer
                      variant="temporary"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                      }}
                      sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                      }}
                    >
                      <NavigationList isCollapsed={false} setIsCollapsed={() => { }} />
                    </Drawer>
                    <Drawer
                      variant="permanent"
                      sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                      }}
                      open
                    >
                      <NavigationList isCollapsed={isDesktopDrawerCollapsed} setIsCollapsed={setIsDesktopDrawerCollapsed} />
                    </Drawer>
                  </Box>
                  <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, display: 'flex', flexDirection: 'column' }}
                  >
                    <NavBar
                      brandSelected={brandSelected}
                      logoutHandler={logoutHandler}
                      handleDrawerToggle={handleDrawerToggle}
                    />
                    <Box
                      component="main"
                      sx={{ overflowY: 'scroll', flexGrow: 10, textAlign: 'center' }}
                    >
                      <Container maxWidth='xl' sx={{ py: 2, height: '100%' }}>
                        {children}
                      </Container>
                    </Box>
                  </Box>
                </Box>
                <Footer />
              </Box>
            </>
          }}
        </Authenticator>
        <CookiePopup open={allowCookies === undefined} onAnswer={rememberMe => setAllowCookies(rememberMe)} />
      </CookieContext.Provider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: any) => ({
  currentBrand: state.user ? state.user.currentBrand : undefined,
})
const ConnectedApp = connect(mapStateToProps, {
  setCurrentBrand: userExport.setCurrentBrand,
  setBrandFromRoute: userExport.setBrandFromRoute
})(App)

export default ConnectedApp
