import {useTranslation} from "react-i18next";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

const Container = styled('div')(({theme}) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
}))

const ButtonContainer = styled('div')(({theme}) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}))

type Props = {
  open: boolean,
  onAnswer: (rememberMe: boolean) => void
}

const CookiePopup = ({ open, onAnswer }: Props) => {
  const { t } = useTranslation();


  const handleAnswer = (rememberMe: boolean) => {
    onAnswer(rememberMe)
  }

  return (
    <Dialog
    sx={{
      "& .MuiDialog-container": {
        justifyContent: "flex-end",
        alignItems: "flex-end"
      }
    }}
    open={open} fullWidth={true}>
      <Container>
        <Typography variant="h6">
          {t("cookiePopup.text")}
        </Typography>
        <ButtonContainer>
          <Button variant="contained" onClick={() => handleAnswer(true)}>{t("cookiePopup.yes")} </Button>
          <Button variant="contained" color="secondary" onClick={() => handleAnswer(false)}>{t("cookiePopup.no")} </Button>
        </ButtonContainer>
      </Container>
    </Dialog>
  )
}

export default CookiePopup;