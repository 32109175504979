import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux'

import { Box, Toolbar } from '@mui/material';
import Divider from '@mui/material/Divider';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import GroupIcon from '@mui/icons-material/Group';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import StoreIcon from '@mui/icons-material/Store';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import SpokeIcon from '@mui/icons-material/Spoke';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import BuildIcon from '@mui/icons-material/Build';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import cmsIcon from './CMS_Logo_icon.svg'

import BrandUser from 'modelTypes/BrandUser';

const NavigationList = ({
  isCollapsed,
  setIsCollapsed,
  username,
  currentBrand,
}: {
  isCollapsed: boolean,
  setIsCollapsed: (isCollapsed: boolean) => void
  username: string,
  currentBrand: BrandUser | undefined,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  let pathName = location.pathname
  const pathComponents = pathName.split('/')
  const isInCollection = pathComponents.length > 3 && pathComponents[2] === "frame-categories" && pathComponents[3] !== "create"
  const collection = isInCollection && pathComponents[3]
  const frame = isInCollection && pathComponents[4]
  const material = pathComponents.length > 3 && pathComponents[2] === "materials" && pathComponents[3]

  if (currentBrand === undefined) {
    return <div>
      <Toolbar variant="dense" />
      <Divider />
      <CircularProgress />
    </div>
  }

  return <div>
    <Toolbar disableGutters={true} variant="dense" sx={{ p: 0, backgroundColor: (theme) => theme.palette.menuBackground.main }}>
      {isCollapsed &&
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            size='small'
            onClick={() => setIsCollapsed(false)}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            <img src={cmsIcon} alt="CMS Logo" />
          </IconButton>
        </Box>
      }
      {!isCollapsed &&
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', width: '100%', px: 1 }}>
          <ListItemButton component={NavLink} to={`/${currentBrand.brand}`} >
            <ListItemIcon sx={{ pl: 0.5 }}>
              <img src={cmsIcon} alt="CMS Logo" />
            </ListItemIcon>
            <ListItemText primary="LooCMS" sx={{ color: 'menuBackground.contrastText' }} />
          </ListItemButton>
          <IconButton
            aria-label="open drawer"
            size='small'
            onClick={() => setIsCollapsed(true)}
            sx={{ display: {  xs: 'none', sm: 'block' }, color: 'menuBackground.contrastText' }}
          >
            <ChevronLeftRoundedIcon />
          </IconButton>
        </Box>
      }
    </Toolbar>
    <Divider />
    <List>
      <ListItemButton component={NavLink} to={`/${currentBrand.brand}`} selected={pathComponents.length <= 2}>
        <ListItemIcon>
          <HomeRoundedIcon />
        </ListItemIcon>
        <ListItemText primary={t("navigation.home")} />
      </ListItemButton>
      {currentBrand.mayEditUsers &&
          <ListItemButton component={NavLink} to={`/${currentBrand.brand}/users`} selected={pathName.includes('/users')}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={t("navigation.users")} />
          </ListItemButton>
      }
      {!currentBrand.mayEditUsers &&
        <>
          <ListItemButton component={NavLink} to={`/${currentBrand.brand}/users/edit/${username}`} selected={pathName.includes('/users/edit')}>
            <ListItemIcon>
              <Person2RoundedIcon />
            </ListItemIcon>
            <ListItemText primary={t("navigation.user")} />
          </ListItemButton>
          <ListItemButton component={NavLink} to={`/${currentBrand.brand}/devices`} selected={pathName.includes('/devices')}>
            <ListItemIcon>
              <HomeRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={t("navigation.devices")} />
          </ListItemButton>
        </>
      }
      {currentBrand.mayEditStores &&
        <ListItemButton component={NavLink} to={`/${currentBrand.brand}/stores`} selected={pathName.includes('/stores')}>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.stores")} />
        </ListItemButton>
      }
      <ListItemButton component={NavLink} to={`/${currentBrand.brand}/orders`} selected={pathName.includes('/orders') && !pathName.includes('/users/orders')}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary={t("navigation.orders")} />
      </ListItemButton>
      {currentBrand.mayEditManagers &&
        <ListItemButton component={NavLink} to={`/${currentBrand.brand}/appconfig`} selected={pathName.includes('/appconfig')}>
          <ListItemIcon>
            <ToggleOnIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.appconfig")} sx={{ display: isCollapsed ? 'none' : 'block' }}/>
        </ListItemButton>
      }
      {currentBrand.mayEditFrames &&
        <>
          <ListItemButton component={NavLink} to={`/${currentBrand.brand}/frame-categories`} selected={pathName.includes('/frame-categories') && !isInCollection} sx={{ minHeight: '3em' }}>
            <ListItemIcon>
              <VoicemailIcon sx={{ transform: 'scale(-1, -1)'}} />
            </ListItemIcon>
            <ListItemText primary={t("navigation.frames")} sx={{ display: isCollapsed ? 'none' : 'block' }}/>
          </ListItemButton>
          {collection &&
            <ListItemButton sx={{ pl: 3 }} component={NavLink} to={`/${currentBrand.brand}/frame-categories/${collection}/show`} selected={isInCollection && !frame}>
              <ListItemIcon>
                <ArrowRightRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={collection} />
            </ListItemButton>
          }
          {collection && frame && frame !== 'show' &&
            <ListItemButton sx={{ pl: 4 }} selected={frame !== undefined}>
              <ListItemIcon>
                <ArrowRightRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={frame} />
            </ListItemButton>
          }
        </>
      }
      {currentBrand.mayEditFrames &&
        <>
          <ListItemButton component={NavLink} to={`/${currentBrand.brand}/materials`} selected={pathName.includes('/materials') && !isInCollection && !material}>
            <ListItemIcon>
              <SpokeIcon />
            </ListItemIcon>
            <ListItemText primary={t("navigation.materials")} />
          </ListItemButton>
          {material &&
            <ListItemButton sx={{ pl: 3 }} selected={true}>
              <ListItemIcon>
                <ArrowRightRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={material} />
            </ListItemButton>
          }
        </>
      }
      {currentBrand.mayRender &&
        <ListItemButton component={NavLink} to={`/${currentBrand.brand}/renderings`} selected={pathName.includes('/rendering') && !isInCollection}>
          <ListItemIcon>
            <ThreeDRotationIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.renderings")} />
        </ListItemButton>
      }
      {currentBrand.mayTriggerBuilds &&
        <ListItemButton component={NavLink} to={`/${currentBrand.brand}/builds`} selected={pathName.includes('/builds') && !isInCollection}>
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.builds")} />
        </ListItemButton>
      }
      {currentBrand.mayEditFrames &&
        <ListItemButton component={NavLink} to={`/${currentBrand.brand}/costs`} selected={pathName.includes('/costs') && !isInCollection}>
          <ListItemIcon>
            <PaidRoundedIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.cost")} />
        </ListItemButton>
      }
    </List>
  </div>
}

const mapStateToProps = (state: any) => ({
  username: state.user.username,
  currentBrand: state.user.currentBrand
})

export default connect(mapStateToProps, {})(NavigationList);