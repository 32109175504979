import { createReducer } from 'typesafe-actions'
import Model from 'modelTypes/Model'
import Category from 'modelTypes/Category'
import Material from 'modelTypes/Material'

const SET_CATEGORIES = 'brand/SET_CATEGORIES'
const SET_MATERIALS = 'brand/SET_MATERIALS'
const SET_MODELS = 'brand/SET_MODELS'
const SET_TAGS = 'brand/SET_TAGS'

type State = {
  categories: Array<Category>
  materials: Array<Material>
  models: { [category: string]: Array<Model> }
  tags: Array<string>
}

const intitialState: State = {
  categories: [],
  materials: [],
  models: {},
  tags: []
}

const reducer = createReducer(intitialState)
  .handleAction(SET_CATEGORIES, (state: State, action: { type: string, categories: Array<Category>}) => {
    return {
      ...state,
      categories: action.categories.sort((a: Category, b: Category) => a.name < b.name ? -1 : 1)
    }
  })
  .handleAction(SET_MATERIALS, (state: State, action: {type: string, materials: Array<Material>}) => {
    return {
      ...state,
      materials: action.materials.sort((a: Material, b: Material) => a.identifier < b.identifier ? -1 : 1)
    }
  })
  .handleAction(SET_MODELS, (state: State, action: {type: string, category: string, models: Array<Model>}) => {
    return {
      ...state,
      models: { ...state.models, [action.category]: action.models }
    }
  })
  .handleAction(SET_TAGS, (state: State, action: {type: string, tags: Array<string> }) => {
    return {
      ...state,
      tags: action.tags.sort((a: string, b: string) => a < b ? -1 : 1)
    }
  })

const full = {
  setCategories: (categories: Array<Category>) => {
    return {
    type: SET_CATEGORIES,
    categories
  }},
  setMaterials: (materials: Array<Material>) => {
    return {
      type: SET_MATERIALS,
      materials
  }},
  setModels: (category: string, models: Array<Model>) => {
    return {
      type: SET_MODELS,
      category,
      models
  }},
  setTags: (tags: Array<string>) => {
    return {
      type: SET_TAGS,
      tags
  }},
  reducer: reducer
}

export default full